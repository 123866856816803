import React from "react";
import { Link } from "gatsby";

import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";
import parse from "html-react-parser";

const HeroPost = ({ postData }) => {
  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `/${process.env.GATSBY_DEFAULT_LANG}/`
      : `/${intl.locale}`;

  const category = postData.categories.nodes.filter(
    (category) => !category.slug.includes("hero-post")
  )[0];
  const authorFirstName = postData.author
    ? postData.author.node.name.split(" ")[0]
    : "Unknown";
  const formattedDate = new Date(postData.date).toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });

  let thumbnail;
  let alt;
  if (postData.featuredImage !== null && postData.featuredImage.node !== null) {
    alt = postData.featuredImage?.node.slug;
    let filteredSizes = postData.featuredImage?.node.mediaDetails.filteredSizes;
    if (filteredSizes.length > 0) {
      let getThumbnail = filteredSizes.filter(
        (img) => img.name === "medium_large"
      );
      thumbnail = getThumbnail[0]?.sourceUrl;
    } else {
      thumbnail = postData.featuredImage?.node.mediaItemUrl;
    }
  } else {
    thumbnail = "thumb";
    alt = "alt";
  }

  return (
    <div className="hero-post">
      <img
        className="hero-post__image"
        loading="lazy"
        src={thumbnail}
        alt={alt}
      />
      <div className="hero-post__wrapper">
        <div className="hero-post__text">
          <h3 className="hero-post__category">{category.name}</h3>
          <h2 className="heading-xl hero-post__title">{postData.title}</h2>
          <div className="hero-post__meta">
            By <span className="hero-post__author">{authorFirstName}</span> -{" "}
            <span className="hero-post__date">{formattedDate}</span>
          </div>
          <p className="hero-post__excerpt">{parse(postData.excerpt)}</p>
          <Link
            className="button"
            to={
              process.env.GATSBY_MULTILINGUAL === "true"
                ? `${locale}${postData.originalSlug}/`
                : `/${postData.originalSlug}/`
            }
          >
            Read Article
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HeroPost;
